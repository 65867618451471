import React from 'react'
import { Link, graphql } from "gatsby"
import { CommentCount } from 'disqus-react'
import { MessageCircle } from 'react-feather'

import { text_truncate } from "../utils/helpers.js"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query blogPageQuery ($skip: Int!, $limit: Int!) {
    allGhostPost(
      sort: {
        order: DESC,
        fields: [published_at]
      },
      filter: {
        slug: {ne: "data-schema"}
      },
      limit: $limit,
      skip: $skip ) {
      edges {
        node {
          id
          slug
          title
          feature_image
          excerpt
          published_at(formatString: "MMMM DD, YYYY")
          authors {
            id
            slug
          }
        }
      }
    }
  }
`

export default class BlogPage extends React.Component {
  render () {

    const disqusConfig = ({ slug, title }) => ({
      shortname: process.env.GATSBY_DISQUS_NAME,
      config: { identifier: slug, title },
    })

    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/blog" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    const { allGhostPost } = this.props.data

    return (
      <Layout>
        <SEO title="ԲԼՈԳ" />
        <div className="blog">
          <div className="blog__title">
            <h2 className="major">ԲԼՈԳ</h2>
          </div>
          <div className="blog__posts">
            {allGhostPost.edges.map(edge => (
              <div key={edge.node.id} className="blog__posts--postItem">
                <div className="blog__posts--postItem-image">
                  <img src={edge.node.feature_image} />
                </div>
                <div className="blog__posts--postItem-content">
                  <h2 key={edge.node.id}>
                    <Link to={`/blog/${edge.node.slug}`}>
                      {edge.node.title}
                    </Link>
                  </h2>
                  <h5>{edge.node.published_at}</h5>
                  <p>{(text_truncate(edge.node.excerpt))}</p>
                  <div className="blog__posts--postItem-content-read_more"
                    key={edge.node.id}
                  >
                    <Link to={`/blog/${edge.node.slug}`}>
                      Read More
                    </Link>
                  </div>
                  {/* <footer>
                    <span>
                    <MessageCircle size={20} />
                    <CommentCount {...disqusConfig({ slug: edge.node.slug, title: edge.node.title})} />
                    </span>
                  </footer> */}
                </div>
              </div>
            ))}
          </div>
          <div className="blog__direction">
            <div className="blog__direction--newer">
              {!isFirst && (
                <Link to={prevPage} className="blog__direction--newer-link" rel="prev">
                  <h3>← Previous Page</h3>
                </Link>
              )}
            </div>
            <div className="blog__direction--older">
              {!isLast && (
                <Link to={nextPage} className="blog__direction--older-link" rel="next">
                  <h3>Next Page →</h3>
                </Link>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}